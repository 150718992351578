import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    vertical-align: baseline;
    color: #fff;
    font-family: 'Fjalla One', sans-serif;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
    background: #000;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    font-family: 'Fjalla One', sans-serif;
    color: #fff;
    text-decoration: none;  
  }

  /* Headings */ 
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-weight: 400;
    line-height: 1.2;
  }
  
  h1 {
    margin: 0 0 calc(16px * 1.5);
  }
  
  h2 {
    margin: 0 0 calc(16px * 1.5);
  }
  
  h3 {
    margin: 0 0 calc(16px * 1.5);
  }
  
  h4 {
    margin: 0 0 calc(16px * 1.5);
  }
  
  h5 {
    margin: 0 0 calc(16px * 1.5);
  }
  
  h6 {
    margin: 0 0 calc(16px * 1.5);
  }
  
  .h1 {
    margin: 0 0 calc(16px * 1.5);
  }
  
  .h2 {
    margin: 0 0 calc(16px * 1.5);
  }
  
  .h3 {
    margin: 0 0 calc(16px * 1.5);
  }
  
  .h4 {
    margin: 0 0 calc(16px * 1.5);
  }
  
  .h5 {
    margin: 0 0 calc(16px * 1.5);
  }
  
  .h6 {
    margin: 0 0 calc(16px * 1.5);
  }
  
  p {
    margin: 0 0 calc(16px * 1.5);
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
  
  ul {
    margin: 0 0 calc(16px * 1.5);
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
  
  ol,li {
    margin: 0 0 calc(16px * 1.5);
    font-family: 'Roboto', sans-serif;
  }
  
  h1 {
    font-size: calc(16px * 2.5);
  }
  
  .h1 {
    font-size: calc(16px * 2.5);
  }
  
  h2 {
    font-size: calc(16px * 1.75);
  }
  
  .h2 {
    font-size: calc(16px * 1.75);
  }
  
  h3 {
    font-size: calc(16px * 1.5);
  }
  
  .h3 {
    font-size: calc(16px * 1.5);
  }
  
  h4 {
    font-size: calc(16px * 1.25);
  }
  
  .h4 {
    font-size: calc(16px * 1.25);
  }
  
  h5 {
    font-size: calc(16px * 1.1);
  }
  
  .h5 {
    font-size: calc(16px * 1.1);
  }
  
  h6 {
    font-size: calc(16px * 1);
  }
  
  .h6 {
    font-size: calc(16px * 1);
  }
  
  b,
  strong {
    font-weight: 600;
  }
  
  small,
  .small {
    font-size: 80%;
    font-weight: normal;
    line-height: 1;
  }


`

export default GlobalStyles
